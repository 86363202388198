import React, { Fragment } from 'react';
import { Styled } from 'theme-ui';
import { Link } from 'gatsby';
import BlogLayout from 'gatsby-theme-blog/src/components/layout';

export default props => (
  <Fragment>
    <BlogLayout {...props}>
      <Styled.p>
        The website for{' '}
        <Styled.a href="https://twitter.com/raganw">Ragan Webber</Styled.a>
      </Styled.p>
      <Styled.p>
        <Styled.ul>
          <Styled.li>
            <Styled.a as={Link} to="/writing">
              writing
            </Styled.a>
          </Styled.li>
          <Styled.li>
            <Styled.a as={Link} to="/taking/notes">
              taking notes
            </Styled.a>
          </Styled.li>
        </Styled.ul>
      </Styled.p>
    </BlogLayout>
  </Fragment>
);
